@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
/* ------------ common css ------------ */
body {
  --bs-body-font-size: 0.875rem;
}

h5.h5 {
  color: #74788d;
}

.red {
  color: #DC6E6E;
}

.blue {
  color: #1F78B4;
}

.text_green {
  color: #42B190;
}

.text_dark_green {
  color: #4588A1;
}

.text_yellow {
  color: #D2A92C;
}

a {
  color: #38A0BB;
}

.text-primary {
  color: #38a0bb !important;
}

.fs-7 {
  font-size: 0.9375rem;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-xs_2 {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #38A0BB;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
}

.text_grey {
  color: #555;
}

.text_blue {
  color: #1F78B4;
}

.text-green {
  color: #1A9AA3;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.parsley-error {
  border-color: #f46a6a;
}

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}

.parsley-errors-list.filled {
  display: block;
}

.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #f46a6a;
  margin-top: 5px;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
}

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.spinner-chase {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: spinner-chase 2.5s infinite linear both;
  animation: spinner-chase 2.5s infinite linear both;
}

.chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: chase-dot 2s infinite ease-in-out both;
  animation: chase-dot 2s infinite ease-in-out both;
}

.chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #1B75BB;
  border-radius: 100%;
  -webkit-animation: chase-dot-before 2s infinite ease-in-out both;
  animation: chase-dot-before 2s infinite ease-in-out both;
}

.chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

@-webkit-keyframes spinner-chase {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-chase {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes chase-dot {
  100%, 80% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes chase-dot {
  100%, 80% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  0%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  0%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  background: radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: scale(0) translate(0, 0);
  transform: scale(0) translate(0, 0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
  background: radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}

.waves-effect.waves-classic .waves-ripple {
  background: rgba(0, 0, 0, 0.2);
}

.waves-effect.waves-classic.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}

.waves-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.waves-button,
.waves-circle {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%);
}

.waves-button,
.waves-button-input,
.waves-button:hover,
.waves-button:visited {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  z-index: 1;
}

.waves-button {
  padding: 0.85em 1.1em;
  border-radius: 0.2em;
}

.waves-button-input {
  margin: 0;
  padding: 0.85em 1.1em;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper.waves-button {
  padding: 0;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
}

.waves-float {
  -webkit-mask-image: none;
  -webkit-box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.waves-float:active {
  -webkit-box-shadow: 0 8px 20px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 20px 1px rgba(0, 0, 0, 0.3);
}

.waves-block {
  display: block;
}

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.4);
}

.waves-effect.waves-primary .waves-ripple {
  background-color: rgba(85, 110, 230, 0.4);
}

.waves-effect.waves-success .waves-ripple {
  background-color: rgba(52, 195, 143, 0.4);
}

.waves-effect.waves-info .waves-ripple {
  background-color: rgba(80, 165, 241, 0.4);
}

.waves-effect.waves-warning .waves-ripple {
  background-color: rgba(241, 180, 76, 0.4);
}

.waves-effect.waves-danger .waves-ripple {
  background-color: rgba(244, 106, 106, 0.4);
}

#page-topbar {
  position: fixed;
  top: 0;
  right: 50px;
  left: 0;
  z-index: 1002;
  background-color: #fff;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 65px;
  padding: 0 calc(24px / 2) 0 0;
}

.navbar-header .dropdown .show.header-item {
  background-color: #f8f9fa;
}

.navbar-brand-box {
  padding: 0 0;
  text-align: center;
  width: 220px;
}

.logo {
  line-height: 65px;
}

.logo .logo-sm {
  display: none;
}

.logo-light {
  display: none;
}

.header-item {
  height: 65px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #555b6d;
  border: 0;
  border-radius: 0;
}

.header-item:hover {
  color: #555b6d;
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: #f6f6f6;
  padding: 3px;
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
    max-width: 100%;
    padding: 0 1.5rem;
  }

  .logo span.logo-lg {
    display: none;
  }

  .logo span.logo-sm {
    display: inline-block;
  }
}
body[data-topbar=dark] .navbar-header .dropdown .show.header-item {
  background-color: rgba(255, 255, 255, 0.05);
}

body[data-topbar=dark] .navbar-header .waves-effect .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}

body[data-topbar=dark] .header-item {
  color: #e9ecef;
}

body[data-topbar=dark] .header-item:hover {
  color: #e9ecef;
}

body[data-topbar=dark] .header-profile-user {
  background-color: rgba(255, 255, 255, 0.25);
}

body[data-topbar=dark] .logo-dark {
  display: none;
}

body[data-topbar=dark] .logo-light {
  display: block;
}

body[data-sidebar=dark] .navbar-brand-box {
  background: #1B2430;
}

body[data-sidebar=dark] .logo-dark {
  display: none;
}

body[data-sidebar=dark] .logo-light {
  display: block;
}

.metismenu {
  margin: 0;
}

.metismenu li {
  display: block;
  width: 100%;
}

.metismenu .mm-collapse {
  display: none;
}

.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}

.metismenu .mm-collapse.mm-show {
  display: block;
}

.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
}

.vertical-menu {
  width: 220px;
  z-index: 1001;
  background: #fff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 65px;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

#sidebar-menu {
  padding: 5px;
}

#sidebar-menu .mm-active > .has-arrow:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

#sidebar-menu .has-arrow:after {
  content: "󰅀";
  font-family: "Material Design Icons";
  display: block;
  float: right;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-size: 1rem;
}

#sidebar-menu ul li a {
  display: block;
  padding: 0.625rem 1rem;
  color: #545a6d;
  position: relative;
  font-size: 14px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#sidebar-menu ul li a i {
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#sidebar-menu ul li a:hover {
  color: #383c40;
}

#sidebar-menu ul li a:hover i {
  color: #383c40;
}

#sidebar-menu ul li .badge {
  margin-top: 4px;
}

#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 2rem;
  font-size: 13px;
  color: #fff;
}

#sidebar-menu ul li ul.sub-menu li a i {
  font-size: 0.9rem;
  min-width: 1.3rem;
}

#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #383c40;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
  font-size: 13px;
}

.mm-active {
  color: #1B75BB !important;
}

.mm-active > a {
  color: #1B75BB !important;
}

.mm-active > a i {
  color: #1B75BB !important;
}

.mm-active .active {
  color: #1B75BB !important;
}

.mm-active .active i {
  color: #1B75BB !important;
}

.mm-active > i {
  color: #1B75BB !important;
}

.main-content {
  margin-left: 220px;
  overflow: hidden;
  margin-right: 52px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.vertical-collpsed .main-content {
  margin-left: 70px;
}

.vertical-collpsed .navbar-brand-box {
  width: 70px !important;
}

.vertical-collpsed .logo span.logo-lg {
  display: none;
}

.vertical-collpsed .logo span.logo-sm {
  display: block;
}

.vertical-collpsed .vertical-menu {
  position: absolute;
  width: 70px !important;
  z-index: 5;
}

.vertical-collpsed .vertical-menu .simplebar-content-wrapper,
.vertical-collpsed .vertical-menu .simplebar-mask {
  overflow: visible !important;
}

.vertical-collpsed .vertical-menu .simplebar-scrollbar {
  display: none !important;
}

.vertical-collpsed .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .badge,
.vertical-collpsed .vertical-menu #sidebar-menu .collapse.in,
.vertical-collpsed .vertical-menu #sidebar-menu .menu-title {
  display: none !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .has-arrow:after {
  display: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  min-height: 55px;
  -webkit-transition: none;
  transition: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:active,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:focus,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:hover {
  color: #383c40;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  font-size: 1.45rem;
  margin-left: 0;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
  display: none;
  padding-left: 25px;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  position: relative;
  width: calc(190px + 70px);
  color: #1B75BB;
  background-color: #f5f5f5;
  -webkit-transition: none;
  transition: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #1B75BB;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
  display: inline;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
  -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
  box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul ul {
  -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
  box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
  color: #545a6d;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #383c40;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: #fff;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li:hover > ul {
  display: block;
  left: 190px;
  height: auto !important;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li > a span.pull-right {
  position: absolute;
  right: 20px;
  top: 12px;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li.active a {
  color: #f8f9fa;
}

body[data-sidebar=dark] .vertical-menu {
  background: #1B2430;
}

body[data-sidebar=dark] #sidebar-menu ul li a {
  color: #fff;
}

body[data-sidebar=dark] #sidebar-menu ul li a i {
  color: #fff;
}

body[data-sidebar=dark] #sidebar-menu ul li a:hover {
  color: #38A0BB;
}

body[data-sidebar=dark] #sidebar-menu ul li a:hover i {
  color: #fff;
}

body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a {
  color: #fff;
}

body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #fff;
}

body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  background: #2e3548;
  color: #fff;
}

body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #fff;
}

body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  color: #79829c;
}

body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #fff;
}

body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  background-color: #2a3042;
}

body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
  color: #fff !important;
}

body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active i {
  color: #fff !important;
}

body[data-sidebar=dark] .mm-active {
  color: #fff !important;
  background: #1f95b6;
}

body[data-sidebar=dark] .mm-active > a {
  color: #fff !important;
}

body[data-sidebar=dark] .mm-active > a i {
  color: #fff !important;
}

body[data-sidebar=dark] .mm-active > i {
  color: #fff !important;
}

body[data-sidebar=dark] .mm-active .active {
  color: #fff !important;
}

body[data-sidebar=dark] .mm-active .active i {
  color: #fff !important;
}

body[data-sidebar=dark] .menu-title {
  color: #6a7187;
}

@media (max-width: 991.98px) {
  .navbar-brand-box .logo-dark {
    display: block;
  }

  .navbar-brand-box .logo-dark span.logo-sm {
    display: block;
  }

  .navbar-brand-box .logo-light {
    display: none;
  }
}
[data-simplebar] {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  padding: 0 !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:after,
.simplebar-content:before {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 4px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  -webkit-transition: opacity 0s linear;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.footer {
  bottom: 0;
  padding: 20px calc(24px / 2);
  position: absolute;
  right: 50px;
  color: #74788d;
  left: 220px;
  height: 60px;
  background-color: #f2f2f5;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

@media (max-width: 991.98px) {
  .footer {
    left: 0;
  }
}
.vertical-collpsed .footer {
  left: 70px;
}

@media (max-width: 991.98px) {
  .vertical-collpsed .footer {
    left: 0;
  }
}
.page-content {
  padding: calc(70px + 24px) calc(24px / 2) 60px calc(24px / 2);
}

.page-title-box {
  padding-bottom: 24px;
}

.page-title-box h4 {
  font-weight: 600;
  font-size: 16px !important;
  color: #1f95b6;
}

.page-title-box .breadcrumb {
  background-color: transparent;
  padding: 0;
}

.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
}

.list-bg .flex-wrap {
  padding: 0.32rem 10px;
}

.list-bg .flex-wrap:nth-child(2n+1) {
  background: #FBFBFB;
}

.security_alerts h3, .security_alerts h4, .users_most_alerts h4, .insights h6, .security_alerts h6 {
  color: #38A0BB;
}

.officeboard_heading {
  color: #444;
  font-weight: 500;
  font-size: 16px;
}

.officeboard_heading span {
  position: relative;
}

.officeboard_heading span::before {
  content: "";
  background: #3877b7;
  height: 3px;
  bottom: -8px;
  right: 0;
  left: 0;
  position: absolute;
}

/* table */
.table-striped.table_even > tbody > tr:nth-of-type(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped.table_even > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: #fff;
  color: var(--bs-table-striped-color);
}

.table-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: #f3f3f9;
  box-shadow: none;
  border-radius: 30px;
}

.table-search span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 13px;
  top: 0;
  color: #74788d;
}

.min-w-auto {
  min-width: auto;
}

.subscription_list .footer.organization {
  left: 0;
}

.vertical_line::after {
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #EBEBEB;
}

/* ------------ common css end ------------ */
/* Login Page , SignUp Page , Confirm-User Page, Create Organization Page CSS */
.login-box {
  background: #E4F3F6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-box h2.h2 {
  color: #38A0BB;
}

.login-box form .form-control {
  background: #F2F4F8;
  border-radius: 100rem;
  border: none;
  padding: 0.7rem 1.5rem;
}

.login-box form .symbol-input {
  font-size: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  top: 11px;
  left: 0;
  width: 100%;
  padding-left: 1.2rem;
  pointer-events: none;
  color: #666;
  transition: all 0.4s;
  z-index: 4;
}

.login-box form .form-control {
  padding-left: 3rem;
}

.login-box form .btn-light {
  border-radius: 0 100rem 100rem 0;
  background-color: #A1A1A1;
  color: #fff;
}

.login-box .btn-primary {
  padding: 0.7rem 1.5rem;
  border-radius: 100rem;
}

.login-box .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 767.98px) {
  .login-wrap {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
@media (min-width: 992px) {
  .login-wrap {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
.iti {
  width: 100%;
}

.iti--separate-dial-code .iti__selected-flag {
  border-radius: 100rem 0 0 100rem;
}

.iti__country-list {
  z-index: 6;
  max-width: 350px;
  font-size: 11px;
}

.iti input#country-search-box {
  padding-left: 6px;
  padding: 6px;
  font-size: 12px;
}

/* Login Page , SignUp Page , Confirm-User Page, Create Organization Page CSS  ENDS HERE*/
/* right bar  */
.right-bar {
  background-color: #fff;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  position: fixed;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 50px;
  z-index: 1002;
  float: right !important;
  right: 0;
  top: 0;
  bottom: 0;
}

.right-bar .right-bar-toggle {
  background-color: #444c54;
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: block;
  color: #eff2f7;
  text-align: center;
  border-radius: 50%;
}

.right-bar .right-bar-toggle:hover {
  background-color: #4b545c;
}

.rightbar-overlay {
  background-color: rgba(52, 58, 64, 0.55);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.right-bar-enabled .right-bar {
  right: -60px;
}

.right-bar-enabled .rightbar-overlay {
  display: block;
}

@media (max-width: 991.98px) {
  .right-bar {
    overflow: auto;
    right: -60px;
  }

  .right-bar .slimscroll-menu {
    height: auto !important;
  }

  .right-bar-enabled .right-bar {
    right: 0;
  }
}
@media print {
  .right-bar {
    display: none !important;
  }

  .right-bar {
    padding: 0;
    margin: 0;
  }
}
.rounded-circle {
  border-radius: 50% !important;
}

/* right_icon_toggle_btn  */
.right_icon_toggle_btn {
  right: 8px;
  bottom: 10px;
  z-index: 999999;
  line-height: 2rem !important;
  border-radius: 100px;
  transition: width 0.2s ease-in-out 0s, right 0.1s ease-in-out 0s;
}

.btn.right_icon_toggle_btn i {
  font-size: 19px;
}

.right_icon_toggle_btn:hover {
  width: 2.375rem;
}

.right_icon_toggle_btn.radius {
  border-radius: 100px 0 0 100px;
  right: 0;
}

.right_icon_toggle_btn.radius:hover {
  width: 3.375rem;
}

.right-bar-enabled .right_icon_toggle_btn i::before {
  content: "󰁍";
}

.btn.btn-icon, .navbar .navbar-nav > a.btn.btn-icon {
  height: 2rem;
  min-width: 2rem;
  width: 2rem;
  padding: 0;
  font-size: 0.8rem;
  overflow: hidden;
  position: relative;
  line-height: 2rem;
}

.right-bar-enabled .main-content {
  margin-right: 0;
}

.right-bar-enabled #page-topbar {
  right: 0;
}

.right-bar-enabled .footer {
  right: 0;
}

.right-bar-enabled .footer ul {
  margin-right: 30px;
}

@media only screen and (max-width: 768px) {
  .block-header .right_icon_toggle_btn {
    display: none;
  }
}
app-subscription-list #page-topbar, conditions #page-topbar, app-privacy-policy #page-topbar, app-subscription-list .footer {
  right: 0;
}

conditions .footer.organization, app-privacy-policy .footer.organization {
  left: 0;
  right: 0;
}

/* pagination  */
.ngx-pagination li {
  border-radius: 30px !important;
  margin: 0 3px !important;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
}

.ngx-pagination .current {
  color: #fff;
  background-color: #38A0BB !important;
  border-color: #38A0BB !important;
  padding: 0 !important;
}

.ngx-pagination a, .ngx-pagination button {
  padding: 0 !important;
}

.ngx-pagination li.pagination-previous, .ngx-pagination li.pagination-next {
  width: auto;
}

.ngx-pagination li.pagination-previous a:hover, .ngx-pagination li.pagination-next a:hover {
  background: none;
}

.ngx-pagination a:hover {
  background: #e6e6e6;
  border-radius: 100%;
  line-height: 30px;
}

.toast-container {
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-container .ngx-toastr {
  width: 830px;
  padding: 10px 10px 10px 50px;
  background-size: 18px;
  box-shadow: none;
}

.toast-container .ngx-toastr:hover {
  box-shadow: none;
}

.toast-message {
  line-height: 27px;
}

.toast-close-button {
  right: 0.3em;
  top: 0;
}

.toast-success {
  background-color: rgba(122, 231, 122, 0.8);
}

.toast-error {
  background-color: #e9645d;
}

.border-bottom {
  border-bottom: 1px solid #919191 !important;
}

@media (min-width: 1279.98px) and (max-width: 1299.98px) {
  .p-3 {
    padding: 0.5rem !important;
  }
}
.device_usage .table .table-light {
  background-color: #F8F8F8;
}

.device_usage .table td, .device_usage .table th {
  padding: 8px 15px;
  border: none;
}

.device_usage .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

.device_usage .table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-striped-bg: #fff;
}

@media (max-width: 991.98px) {
  .w-90 {
    width: 95%;
  }

  #page-topbar {
    right: 0;
  }

  .footer {
    right: 0;
  }

  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
    margin-right: 0;
  }

  body.sidebar-enable .vertical-menu {
    display: block;
  }
}
#sidebar-menu ul li .badge.bg-success {
  background: #38A0BB !important;
}

.custom_tabs .nav-tabs .nav-link {
  border-radius: 0;
  background: #e2e0e1;
  border-top: 4px solid #c1c1c1;
  margin-right: 5px;
}

.custom_tabs .nav-tabs .nav-link:hover {
  color: #1A9AA3;
}

.custom_tabs .nav-tabs .nav-item.show .nav-link, .custom_tabs .nav-tabs .nav-link.active {
  border-top: 4px solid #1f95b6;
  background: #fff;
}

.custom_tabs .tab-content {
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}

.text-blue-light {
  color: #03a9f4;
}

.text_alerts {
  color: #f46a6a;
}

.alerts_bg {
  background: #f46a6a;
}

.sort::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "";
  box-sizing: border-box;
  font-size: 11px;
  color: #7e7e7e6b;
  margin-left: 10px;
}

.asc::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "";
  box-sizing: border-box;
  font-size: 11px;
  color: #1f95b6;
  margin-left: 10px;
}

.desc::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "";
  box-sizing: border-box;
  font-size: 12px;
  color: #1a9aa3;
  margin-left: 10px;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 67% !important;
}

.w_1_7 {
  width: 1.7rem;
}

.w-38 {
  width: 38% !important;
}

.rounded_5 {
  border-radius: 5px;
}

.text-blue {
  color: #3877b7;
}

.fixed_tooltip .tooltip-inner {
  max-width: 350px;
  /* If max-width does not work, try using width instead */
  width: 350px;
}

.fixed_tooltip .tooltip-inner {
  max-width: 100%;
  width: 100%;
  padding: 15px;
  background: #1f95b6;
  margin-left: 0;
  color: #fff;
  text-align: left;
  box-shadow: 1px 1px 5px #115669;
}

.fixed_tooltip .tooltip {
  transform: translate(0px, 22px) !important;
  margin-left: 0;
  margin-right: 0;
}

.fixed_tooltip .tooltip-inner a {
  color: #ffec7d !important;
  word-break: break-all;
}

.fixed_tooltip .tooltip-inner a:hover {
  color: #263238 !important;
}

.fixed_tooltip .bs-tooltip-bottom .arrow::before {
  border-bottom-color: #1f95b6;
}

.fixed_tooltip .mdi-information-outline {
  left: calc(49% - .4rem);
  position: absolute;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.25rem 0.25rem;
  border-bottom-color: #000;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
  top: 0;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.5rem;
  height: 0.4rem;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  white-space: nowrap;
  padding: 0;
}

.ellipsis:focus, .ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after, .ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  max-width: 20rem;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: normal;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
}

.truncate_table {
  border-collapse: collapse;
  table-layout: fixed;
}

.truncate_table .long-td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (min-width: 1200px) {
  .w_9rem {
    width: 9rem;
  }
}
@media (min-width: 1400px) {
  .w_9rem {
    width: 15rem;
  }
}
.table-md td, .table-md th {
  padding: 0.625rem 1.25rem;
}